@import "~antd/dist/antd.less";
@import "~@/assets/font/iconfont.less";
@import "~@/assets/style/common.less";
@font-face {
  /* font-family: 'siyuan';
  src:url('./assets/font/siyuan.otf'); */
  font-family: "pingfang";
  src: url("./assets/font/PingFang_SC_Regular.ttf");
}


* {
  //font-family: "pingfang";
  margin: 0;
  padding: 0;
}

//placeHolder样式
*::-webkit-input-placeholder {
  color:rgba(255,255,255,.45);
}
*:-moz-placeholder {
  /* FF 4-18 */
  color:rgba(255,255,255,.45);
}
*::-moz-placeholder {
  /* FF 19+ */
  color:rgba(255,255,255,.45);
}
*:-ms-input-placeholder {
  /* IE 10+ */
  color:rgba(255,255,255,.45);
}

//全局隐藏滚动条

a {
  text-decoration: none;
}

body {
  background: #101528 !important;
   .cardScrollStyle(#101529,20);
  &:hover {
    .cardScrollStyle(#101529,0);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site-layout-content {
  width: 100%;
  height: 800px;
  background-color: #ccc;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

input,
textarea,
select {
  outline: none;
}

.app-layout-warp{
  background: #101528 !important;
  &::-webkit-scrollbar {
    width: 0px;
  }
}

iframe{
  display: none !important;
}




