@font-face {
  font-family: "iconfont"; /* Project id 3032372 */
  src: url("//at.alicdn.com/t/font_3032372_do6qbr8v5gm.woff2?t=1639979430595")
      format("woff2"),
    url("//at.alicdn.com/t/font_3032372_do6qbr8v5gm.woff?t=1639979430595")
      format("woff"),
    url("//at.alicdn.com/t/font_3032372_do6qbr8v5gm.ttf?t=1639979430595")
      format("truetype");
}

.icon {
  font-family: "iconfont" !important;
  font-size: 12px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-triangle:before {
  content: "\e660";
}

.icon_jt:before {
  content: "\e661";
}

.icon_cinema:before {
  content: "\e662";
}

.Icon_search:before {
  content: "\e663";
}

.warning-circle-fill:before {
  content: "\e664";
}

.info-circle:before{
  content: "\e665";
}

.icon_password:before{
  content:"\e666"
}

.icon_user:before{
  content:"\e667"
}

.icon_users:before{
  content:"\e668"
}

.icon_gz:before{
  content:"\e66d"
}

.icon_s:before{
  content:"\e66c"
}

.icon_cinema:before{
  content:"\e66b"
}

.icon_xt:before{
  content:"\e669"
}

.close-circle-fill:before{
  content:"\e66e"
}

.check-circle-fill:before{
  content:"\e66a"
}
