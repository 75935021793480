@titleColor:rgba(255,255,255); /*标题*/
@normalColor:rgba(255,255,255,0.45); /*普通文字*/
@borderColor:rgba(255,255,255,0.2); /*边框*/
@lineColor:rgba(255,255,255,0.1); /*分割线*/
@selectHover:#282e43; /*选择浮动*/
@selectHoverLightHeight:#353E5F;
@selected:#162A40;
@dialogBgColor:#191D2C;/*弹框背景色*/
@hoverHeightLightColor:rgba(24,144,255);
@tipsBoxColor:rgba(24,144,255,.15);
@tipsBoxBorderColor:rgba(24,144,255,.5);
@cardBgColor:#1C2135;
//最外层包体样式
.warp-box(@top){
    width: 100%;
    /* min-height: 100vh; */
    padding: @top 0;
    .cardScrollStyle(#101529,0);
    &:hover {
      .cardScrollStyle(#101529,20);
    }
}


//面包屑分隔符
.ant-breadcrumb-separator{
    color:@normalColor;
}
/* //外部卡片尺寸
   */

//屏幕尺寸适配
    @media screen and (max-width: 2560px){
        :root{
            --warpWidth:1440px;
          }
    }
    @media screen and (max-width: 1920px){
        :root{
            --warpWidth:1350px;
          }
    }
    @media screen and (max-width: 1336px){
        :root{
            --warpWidth:1000px;
        }
    }

//选择下拉框定制样式
.select-item{
    .textColor(75);
     background-color:@selectHover;
     font-size:12px;
}

.ant-modal-header{
    background: @dialogBgColor;
    border-bottom:1px solid @lineColor;
    .ant-modal-title{
        .textColor(100);
        font-size:16px;
        font-weight: 500;
    }
    
}

.ant-modal-close{
    .textColor(45);
    &:hover{
     .textColor(45); 
    }
}

//选择框弹框样式
.ant-select-item{
    .select-item();
    &:hover{
        background-color:@selectHoverLightHeight;   
    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    .textColor(75);
    background-color:@selected;
    &:hover{
        background-color:@selectHoverLightHeight;   
    }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled){
    .textColor(75);
     background-color:@selectHoverLightHeight;
}


//时间日期选择框弹框样式
.ant-picker-panel-container{
    background-color:@selectHover;
    .ant-picker-header{
        border-bottom:1px solid @lineColor;
        .textColor(85);
        button{
            color:@normalColor;
        }
    }
    
    .ant-picker-date-panel .ant-picker-content th{
      .textColor(85);
    }
    
    .ant-picker-cell{
        .textColor(25);
    }

    .ant-picker-cell-in-view{
        .textColor(65);
    }

    .ant-picker-cell-in-range::before{
        content:"";
        background-color:@selected;
    }
    
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before
     {
        background-color:@selected;
     }

    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before{
        background-color:@selectHoverLightHeight; 
     }

     .ant-picker-cell-in-view.ant-picker-cell-in-range::before{
        background-color:@selected;
     }
     .ant-picker-range-arrow{
        visibility: hidden;
     }
    
     .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before{
        background-color:@selectHoverLightHeight;
     }

    
    .ant-picker-panel{
        border-bottom:1px solid @lineColor;
    }

    .ant-picker-datetime-panel .ant-picker-time-panel{
        border-left:1px solid @lineColor;
        .ant-picker-time-panel-column:not(:first-child){
            border-left:1px solid @lineColor; 
        }
       
    }
    .ant-picker-ok{
        .ant-btn-primary[disabled],.ant-btn-primary[disabled]:hover{
            background-color:@cardBgColor;
        }
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover{
        background-color:@selectHoverLightHeight; 
        
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{
        background-color:@selected;  
    }
    .ant-picker-time-panel-column{
        .cardScrollStyle(@selectHover,0);
        &:hover {
        .cardScrollStyle(@selectHover,20);
        }
    }
    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner{
        .textColor(65);
    } 
}

.ant-picker-range-arrow{
    color:@selectHover;
    background:linear-gradient(135deg, transparent 40%, #282e43 40%);
    &::after{
        border:5px solid @selectHover;
        border-color: @selectHover;
    }
}

//各种透明度的文字样式
.textColor(@opacity){
    color:rgba(255,255,255,@opacity * 0.01);
}

//各种透明度的白色背景
.bgColor(@opacity){
    background-color:rgba(255,255,255,@opacity * 0.01);
}

//正方形样式
.rectIcon(@size){
   width:@size;
   height:@size;
}

//状态点样式
.dotStyle(@bgColor,@opacity){
        width:8px;
        height:8px;
        border-radius:50%;
        background-color:@bgColor;
        opacity:@opacity*0.01
}

//提示样式
.tipsIcon(@size){
    width:@size;
    height:@size;
    display: flex;
    align-content: center;
    justify-content: center;
    img{
        width:100%;
        height:100%;
    }
}

//弹窗背景样式
.dialogStyle(){
    .borderRadius(4px);
    background-color:@dialogBgColor;
    overflow: hidden;
}



//统一圆角样式
.borderRadius(@deg){
    border-radius: @deg;
}



.ant-popover-inner-content{
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.6);
    padding:10px 14px;
}

//弹窗背景
.ant-modal-content{
    .dialogStyle();
}

//隐藏滚动条样式 暂时无用
.hideScrollStyle(){
    &::-webkit-scrollbar {
        width:0px;
    }
}

//文字超出隐藏
.textHiddenStyle(){
    overflow: hidden;/*超出部分隐藏*/
    white-space: nowrap;/*不换行*/
    text-overflow:ellipsis;/*超出部分文字以...显示*/
}

//输入框样式
.ant-input{
    .borderRadius(2px);
    background-color:@dialogBgColor ;
    border-color:@borderColor !important;
   .textColor(75);
   &::placeholder{
    .textColor(20)
   }
}

.ant-input[disabled]{
    background-color:rgba(255,255,255,.08);
    .textColor(20);
}



.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
    .textColor(75);
    background-color:@cardBgColor;
    border-color: #A61D24 !important;
    &:hover{
       .textColor(75);
       background-color:@cardBgColor;
    }
}

//输入框前缀样式
.ant-input-affix-wrapper{
   .borderRadius(4px);   
    background-color:@dialogBgColor;
    border-color:@borderColor;
}

.ant-input-prefix{
    margin-right:9px;
    .textColor(45);
}

//多选框样式
.ant-checkbox + span{
   .textColor(75);
   font-size:12px;
}

.ant-checkbox-inner{
    .borderRadius(2px); 
    .rectIcon(16px);  
    background-color:@cardBgColor;
    border-color:@borderColor;
}

//表格cell内部样式
.ant-table-tbody > tr.ant-table-row-selected > td{
    background:@cardBgColor;
}

//表格内置样式修改
.ant-table-style(){
    .tableBox .ant-table{
        background-color:@cardBgColor;
            thead {
                tr th{
                    .textColor(85);
                    background-color:@selectHover;
                    border-bottom:none;
                }
            }
   
            tbody {
             tr{
                   .textColor(75);
                   font-size: 14px;
                   font-weight:400;
                   background-color:@cardBgColor;
                   td{
                       border-bottom:1px solid @lineColor;
                   }
                   &:hover>td{
                       background-color:@cardBgColor ;  
                   }
               }
                 } 
   
             .ant-table-tbody > tr.ant-table-placeholder:hover > td{
                   background-color:@cardBgColor ;  
             }
   
             .ant-empty-description{
                 .textColor(75);
             }
           }
}


//分页样式修改
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
    background-color:@cardBgColor;
    border: 1px solid @borderColor;
    .textColor(65);
    font-weight: 400;
    font-size:14px;
}

.ant-pagination-item-active{
    background: @hoverHeightLightColor;
    border-color: @hoverHeightLightColor;
    a{
        .textColor(65);
    }
}

//操作按钮样式
.buttonStyle{
    color:@hoverHeightLightColor;
    cursor: pointer;
    font-size:14px;
    display: inline-block;
    margin-right:30px;
    &:last-child{
        margin-right: 0;
    }
}



//卡片滚动条样式
.cardScrollStyle(@ScrollBgColor,@opacity){
    /*滚动条凹槽的颜色，还可以设置边框属性 */
    &::-webkit-scrollbar-track-piece {
        background-color:@ScrollBgColor;
        margin-top:4px;
    }
    /*滚动条的宽度*/
    &::-webkit-scrollbar {
        width:6px;
        height: 6px;
    }
    /*滚动条的设置*/
    &::-webkit-scrollbar-thumb {
        .bgColor(@opacity);
        background-clip:padding-box;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        opacity:0.5;
    }
    
    /*滚动条鼠标移上去*/
    &::-webkit-scrollbar-thumb:hover {
        .bgColor(@opacity);
    }

}
//外部包裹样式
.layOutStyle(@height,@bottom) {
    width: 100%;
    min-height: @height;
    margin-bottom: @bottom;
    /* .bgColor(30); */
    .borderRadius(2px);
  }


//ant表单样式
.ant-form-item-label > label{
    .textColor(75);
    font-size:14px;
    padding:0 0 2px;
}

//ant 单选样式
.ant-radio-wrapper{
    .textColor(75);
}

.ant-radio-button-wrapper{
    background-color:@cardBgColor;
    border-color:@borderColor;
    .textColor(75);
    &:first-child{
        border-color:@borderColor; 
    }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background-color:@cardBgColor;
}

.ant-radio-button-wrapper:not(:first-child)::before{
    background-color:@borderColor;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background-color:@hoverHeightLightColor;
}

.ant-radio-button-wrapper-disabled:first-child, .ant-radio-button-wrapper-disabled:hover{
    background-color:@hoverHeightLightColor;
    border-color:@borderColor;
    .textColor(75)
}


.ant-radio-inner{
    background-color:@cardBgColor;
}

//分割线背景色
.dividerStyle{
    background-color:@borderColor;
}



//默认按钮样式
.defaultButtonStyle(){
    .textColor(65);
    margin-right:12px;
    background-color:@cardBgColor;
    border-color:@borderColor;
}

.defaultButtonStyleAsync{
    .textColor(65);
    background-color:@cardBgColor;
    border-color:@borderColor;
    &:hover{
        color:@hoverHeightLightColor;
        border-color:@hoverHeightLightColor;
        background-color:@cardBgColor;
    }
}

//级联选择框样式
.ant-cascader-menus{
        background-color:@selectHover;
    .ant-cascader-menu{
        background-color:@selectHover;
        border-radius: 2px;
        border-right: none;
        .hideScrollStyle();
        .ant-cascader-menu-item{
            .textColor(75);
            .ant-cascader-menu-item-expand-icon{
                .textColor(25);
            }
        }
        .ant-cascader-menu-item:hover{
            background-color:@selectHoverLightHeight;
        }
        .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover{
            background-color:@selectHoverLightHeight;
        }
    }
}

//提示框样式
.tipsBoxStyle(@padding){
    width:100%;
    padding:@padding;
    background-color:@tipsBoxColor;
    border:1px solid @tipsBoxBorderColor;
    border-radius: 4px;
}

//分页框样式
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        background-color:@cardBgColor !important;
        border-color:@borderColor;
        .textColor(75);
    }


    
    .ant-select-arrow{
        .textColor(75)
    }

    .ant-select-dropdown{
        background-color:@cardBgColor; 
    }

    .ant-pagination{
        .textColor(45);
    }


//遮罩层样式
.ant-scrolling-effect{
    width: 100% !important;
}    

//按钮字体间距调整
.ant-btn > span{
    letter-spacing: 0px;
}

//禁用按钮样式
.ant-btn-primary[disabled],.ant-btn-primary[disabled]:hover{
    color: rgba(255,255,255,.75);
    border:none;
    background: rgba(255,255,255,.3);
    text-shadow: none;
    box-shadow: none;
}

//时间选择器组件样式调整
.ant-picker-panel .ant-picker-footer{
    border-top:1px solid rgba(255,255,255,.12);
}

.ant-picker-time-panel-column:not(:first-child){
    border-left:1px solid rgba(255,255,255,.12);
}

//表格样式整改
.ant-table-tbody>tr>td.ant-table-cell-row-hover{
    background-color:@cardBgColor;
}

//多选框禁用样式
.ant-checkbox-disabled .ant-checkbox-inner{
    background-color:rgba(255,255,255,.08);
    border: 1px solid rgba(255,255,255,0.2) !important;
    
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after{
    border-color: rgba(255,255,255,.3);
}

//树样式修改
.ant-tree-checkbox-inner{
    background-color: @cardBgColor;
    border-color:rgba(255,255,255,0.2);
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected{
    background-color: @cardBgColor;
}

.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow{
    visibility: hidden;
}

.ant-input-group-addon{
    background-color: @dialogBgColor;
    border:none;
} 

//input 内部选择框样式
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    background-color: @dialogBgColor;
    border:1px solid @borderColor;
}

//button 悬停样式
/* .ant-btn:hover,  */.ant-btn:focus{
    color:@hoverHeightLightColor;
    background-color: @cardBgColor;
    border-color: @hoverHeightLightColor;
}



//分页省略号样式
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis{
    .textColor(20);
}

//切换框聚焦样式
.ant-switch-checked:focus{
    box-shadow: none;
}

//表单头样式修改
.ant-table-container table > thead > tr:first-child th:first-child{
    border-top-left-radius:0;
}

.ant-table-container table > thead > tr:first-child th:last-child{
    border-top-right-radius:0;
}

.ant-table-pagination.ant-pagination{
    padding:0 15px;
    box-sizing: border-box;
}

//时间选项样式
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner{
    background: #1f2642;
}

//时间组件清除样式
.ant-picker-clear{
    background-color: @cardBgColor;
     .textColor(20);
    &:hover{
     .textColor(20);
    }
}
//修改浏览器默认样式
input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px @cardBgColor inset !important;
    -webkit-text-fill-color:rgba(255,255,255,.75) !important;
    -webkit-box-shadow:0 0 0px 1000px transparent inset !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
}

//时间选择组件箭头颜色修改
.ant-picker-range-arrow::before{
    background:#282e43;
}












    


   

